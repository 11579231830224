const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/3.png",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/4.png",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/5.png",
    text: "5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/6.png",
    text: "6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/7.png",
    text: "7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/8.png",
    text: "8",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/9.png",
    text: "9",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/10.png",
    text: "10",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/11.png",
    text: "11",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/12.png",
    text: "12",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/pasrichafarmhouse/13.png",
    text: "13",
  },
];

export default data;
